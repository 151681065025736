<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top pn-promos-banner-container w-full">
            <div class="relative h-full w-full flex justify-center items-start flex-col text-white-1">
                <img class="absolute top-0 right-0 h-full object-cover z-5" src="../../../assets/images/home-page/pn-promos/pn-promos-banner.png" alt="Promos Banner">
                <div class="flex flex-col mx-44 mobileL:mx-0 item z-10 p-0 mobileL:p-4 text-left mobileL:text-center ml-6 mobileL:ml-20 lg:ml-32 2xl:ml-44 
                relative mt-8 mobileL:mt-0 max-w-xs mobileL:max-w-xs lg:max-w-md 2xl:max-w-xl">
                    <h1 class="pn-promos-banner-title font-kannada font-bold mobileS:text-2xl xs:text-3xl md:text-5xl max-w-sm 2xl:max-w-lg lg:text-6xl 2xl:text-8xl z-10">
                        Promo<span class="hidden mobileL:inline">s</span>
                    </h1>
                    <span class="pn-promos-bg-highlight absolute w-3/5 mobileL:w-full h-4 mobileS:h-7 mobileL:h-1/6 2xl:h-16 top-3 mobileL:top-8 lg:top-10 2xl:top-12 z-0"></span>
                    <img class="absolute mobileL:hidden -top-2 shine-position w-3.5 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                    <p class="mobileL:font-kannada mobileL:mt-4 2xl:mt-6 text-sm mobileL:text-lg lg:text-2xl 2xl:text-4xl -mx-4 2xl:-mx-8 text-white-1
                        leading-tight mobileL:leading-normal pl-4 -mr-20 mobileL:mr-0">
                        We love offering special (and even exclusive) promos from 
                        time-to-time! Scroll down to know the best deals and 
                        discounts you can snag NOW!
                    </p>
                </div>
            </div>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/home-page/pn-promos/pn-promos-body.png" alt="Background Image">
            <div class="relative flex items-center justify-center text-white-1 mx-4">
                <img class="mobileL:mr-4 w-1/2 my-12" src="../../../assets/images/home-page/pn-promos/pn-ribbon.png" alt="Ribbon">
                <p class="font-kannada w-2/3 mobileL:w-2/5 text-sm mobileL:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl text-center my-6 leading-snug mobileL:leading-normal">
                We love offering special (and even exclusive) promos from time-to-time! 
                Get a FREE eyebrow threading and a 15% off when you avail any of our waxing 
                and threading services on your birth month!
                </p>
            </div>
        </div>
        <div class="bg-green-15 py-4 mobileL:py-8 lg:py-12 2xl:py-24 text-white-1 relative">
            <div class="text-center flex flex-col items-center mobileL:mb-4 relative">
                <div class="relative inline-block">
                    <span class="pn-frequency-card-highlight absolute top-4 mobileL:top-8 lg:top-10 2xl:top-12 left-0 w-full h-1/3 2xl:h-1/2 z-10"></span>
                    <div class="text-2xl mobileL:text-5xl lg:text-6xl xl:text-7xl 2xl:text-9xl font-semibold mobileL:pb-6 relative z-20">
                        <h2 class="font-kannada font-semibold">Frequency Card Rewards</h2>
                    </div>
                </div>
                <div class="w-4/5 mobileL:w-3/5">
                    <p class="text-white-1 font-kannada text-sm mobileL:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl text-center">
                        For a minimum spend of P300.00 per visit; 
                        we'll give you a sticker, a stamp and a signature 
                        on each frequency card box to verify your visit. 
                        You'll get P100.00 OFF on your 5th visit and P200.00 
                        OFF on your 10th visit
                    </p>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/PassionailsFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/passionails/promos')
    },
}
</script>

<style>
.pn-promos-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #f0acbb 0%, #eeabb7 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .pn-promos-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .pn-promos-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .pn-promos-banner-container{
        height:255px;
        background: linear-gradient(180deg, #C87F93 0%, #D8ACBB 100%);
    }
    .space-top {
    margin-top: 60px;
    }
}

.pn-promos-banner-title {
    color: #FFF;
    font-style: normal;
}

.pn-promos-bg-highlight {
    position: absolute;
    z-index: 0;
    background: #7D4979;
    left: 0px;
}

.pn-frequency-card-highlight {
    position: absolute;
    background: #E1B6EB;
    left: 0px;
}

.shine-position {
    left:-8px
}

</style>